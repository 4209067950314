.lobby__spotlights {
  @extend %box-neat-force;
  flex: 0 0 auto;
}
.tour-spotlight {
  @extend %flex-center-nowrap, %nowrap-hidden, %page-metal;
  flex: 0 0 auto;
  padding: .3em;
  opacity: 0.8;
  @include transition();
  text-shadow: $text-shadow;
  color: $c-font;
  &.invert,
  &.event-spotlight,
  &:hover {
    @extend %page-metal-hover;
    text-shadow: none;
  }
  &:hover {
    opacity: 1;
  }
  .img {
    flex: 0 0 50px;
    margin: 0 .5em 0 .3em;
  }
  img.img {
    width: 40px;
  }
  i.img,
  .img.icon {
    margin: 0 5px 0 0;
  }
  i.img::before {
    color: #fff;
    font-size: 50px;
    @if $theme-light {
      text-shadow: 1px 1px 2px $c-link;
    }
  }
  .name {
    margin-top: 1px;
    line-height: 13px;
    display: block;
  }
  .headline {
    display: block;
    font-size: .85em;
    margin-bottom: -3px;
  }
  .more {
    font-size: .85em;
    time {
      margin-left: 3px;
    }
  }
  &.little {
    .img {
      flex: 0 0 40px;
      margin: 0 .5em 0 .3em;
      &::before {
        font-size: 40px;
      }
    }
  }
}
