.lobby__streams {
  .stream {
    @extend %roboto, %nowrap-ellipsis, %page-text;
    color: $c-font-page;
    display: block;
    margin-bottom: .5em;
    line-height: .9;
    strong {
      @extend %base-font;
    }
    &.highlight strong {
      color: $c-brag;
    }
    strong:before {
      font-size: 1.5em;
      margin-right: .1rem;
    }
  }
}
