@import 'pool';
@import 'hook-chart';
@import 'hook-list';

.lobby__app {
  @extend %flex-column;
  @include lobby-app-size;
  user-select: none;

  .lobby-nope & {
    display: none;
  }

  &__content {
    @extend %box-neat;
    flex: 1 1 100%;
    position: relative;
    @if $theme == 'transp' {
      background: fade-out($c-bg-box, .2);
    } @elseif $theme-light {
      background: $c-bg-zebra url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMTAuMCAyMTAuMCI+PGcgb3BhY2l0eT0iLjEiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjYiPjxnIGZpbGw9IiNmZmYiPjxwYXRoIGQ9Ik0gMTAsMTQwIGMgMCw2MCAxOTAsNjAgMTkwLDAgbCAwLC0zNSBsIC0xOTAsMCB6Ii8+PHBhdGggZD0iTSAxMCwxMDUgYyAwLDYwIDE5MCw2MCAxOTAsMCBsIDAsLTM1IGwgLTE5MCwwIHoiLz48cGF0aCBkPSJNIDEwLDcwIGMgMCw2MCAxOTAsNjAgMTkwLDAgYyAwLC02MCAtMTkwLC02MCAtMTkwLDAgeiIvPjwvZz48L2c+PC9zdmc+');
    } @elseif $theme-dark {
      background: $c-bg-box url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMTAuMCAyMTAuMCI+PGcgb3BhY2l0eT0iLjEiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjYiPjxnIGZpbGw9IiMwMDAiPjxwYXRoIGQ9Ik0gMTAsMTQwIGMgMCw2MCAxOTAsNjAgMTkwLDAgbCAwLC0zNSBsIC0xOTAsMCB6Ii8+PHBhdGggZD0iTSAxMCwxMDUgYyAwLDYwIDE5MCw2MCAxOTAsMCBsIDAsLTM1IGwgLTE5MCwwIHoiLz48cGF0aCBkPSJNIDEwLDcwIGMgMCw2MCAxOTAsNjAgMTkwLDAgYyAwLC02MCAtMTkwLC02MCAtMTkwLDAgeiIvPjwvZz48L2c+PC9zdmc+');
    }
    background-size: 100% 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .lredir {
    background: $c-bg-box;
    display: flex;
    .spinner {
      width: 100px;
      height: 100px;
    }
  }

  .toggle {
    position: absolute;
    padding: .6em;
    cursor: pointer;
    z-index: 2;
    font-size: 1.3em;
    &.toggle-filter {
      right: 0;
    }
    @include transition();
    &:hover {
      color: $c-accent;
    }
  }

  .gamesFiltered {
    color: $c-accent;
    @include transition();
    &:hover {
      color: $c-accent-dim;
    }
  }
}
