.lobby__forum {
  li {
    margin: .6em 0;
    padding-left: 9px;
    line-height: 14px;
    white-space: nowrap;
  }
  .extract {
    @extend %roboto;
  }
}
