.lobby__about {
  display: flex;
  align-content: flex-start;
  flex-flow: row wrap;
  font-size: .85em;
  a {
    @extend %page-text;
    font-weight: bold;
    margin-right: 1.2em;
    white-space: nowrap;
    @include transition(color);
    &:hover{
      color: $c-link;
    }
  }
}
