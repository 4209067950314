.lobby__app-pools {
  height: 300px;
  @include breakpoint($mq-col2) {
    height: 400px;
  }
}

.lpools {
  @extend %roboto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 9px;
  padding: 9px;
  @include fluid-size('font-size', 16px, 29px);
  > div {
    @extend %flex-column, %box-radius, %break-word;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: $border;
    @if $theme-light {
      background: fade-out($c-bg-box, .5);
    } @else {
      background: fade-out($c-font, .95);
    }
    color: $c-font;
    @include transition();
    &:hover {
      background: fade-out($c-accent, .8);
      opacity: 1;
    }
  }
  .active {
    @extend %popup-shadow;
    background: $c-bg-popup;
    .perf {
      display: none;
    }
  }
  .transp {
    opacity: 0.4;
  }
  .spinner {
    flex: 0 0 auto;
    margin: .1em 0 .6em 0;
    width: 2em;
    height: 2em;
    pointer-events: none;
  }
  .clock {
    display: block;
    font-size: 1.5em;
    line-height: 1.6em;
    letter-spacing: .1em;
  }
}
